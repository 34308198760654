import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import toDate from 'date-fns/toDate';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isFuture from 'date-fns/isFuture';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isWithinInterval from 'date-fns/isWithinInterval';
import { doggyDayCareService, dogWalkingService } from '../Pawshake/Core/Service';

export const CONSECUTIVE_DATE_SELECTION = 'consecutive';
export const NON_CONSECUTIVE_DATE_SELECTION = 'nonConsecutive';

export const getTypeOfDateSelectionByServiceId = (serviceId) => {
    return [dogWalkingService.id, doggyDayCareService.id].includes(serviceId)
        ? NON_CONSECUTIVE_DATE_SELECTION
        : CONSECUTIVE_DATE_SELECTION;
};

export const toEuropeanFormat = (date, separator = '/') => {
    let castedDate;
    if (typeof date === 'string') {
        castedDate = parseISO(date);
    } else if (typeof date === 'object') {
        castedDate = toDate(date);
    } else {
        castedDate = date;
    }

    return format(castedDate, `dd${separator}MM${separator}yyyy`);
};

export const formatDate = (date) => {
    if (date) {
        return format(date, 'd MMM yyyy');
    }
    return null;
};

export const filterOutPastDates = (dateCollection) => {
    return dateCollection.filter((date) => {
        let castedDate;
        if (typeof date === 'string') {
            castedDate = parseISO(date);
        } else if (typeof date === 'object') {
            castedDate = toDate(date);
        } else {
            castedDate = date;
        }

        return isToday(castedDate) || isFuture(castedDate);
    });
};

export const parseSearchFilterDates = (selectedDays) => {
    return selectedDays.map((dayString) => parse(dayString, 'yyyy-MM-dd', new Date()));
};

export const formatSearchFilterDates = (selectedDays) => {
    return selectedDays.map((day) => format(day, 'yyyy-MM-dd'));
};

export const determineDisabledDaysForConsecutive = (firstDay, lastDay, unavailableDates) => {
    let nextUnavailableDay;
    let previousUnavailableDay;

    if (firstDay) {
        unavailableDates.forEach((day) => {
            if (isBefore(day, firstDay)) {
                if (!previousUnavailableDay) {
                    previousUnavailableDay = day;
                } else if (
                    isWithinInterval(day, {
                        start: previousUnavailableDay,
                        end: firstDay,
                    })
                ) {
                    previousUnavailableDay = day;
                }
            }
        });

        unavailableDates.forEach((day) => {
            if (isAfter(day, firstDay)) {
                if (!nextUnavailableDay) {
                    nextUnavailableDay = day;
                } else if (
                    isWithinInterval(day, {
                        start: firstDay,
                        end: nextUnavailableDay,
                    })
                ) {
                    nextUnavailableDay = day;
                }
            }
        });
    }

    let entries = [];

    if (previousUnavailableDay) {
        entries.push({ before: previousUnavailableDay });
    }

    if (nextUnavailableDay) {
        entries.push({ after: nextUnavailableDay });
    }

    return entries;
};
